import axios from 'axios';
import {Loading, Message, MessageBox} from 'element-ui';
import router from '../router'

const instance = axios.create({
    // baseURL: 'http://yike.e-class.me/',
    // timeout: 3000
});

let loadingInstance = null;

/* 请求拦截器 */
instance.interceptors.request.use((config) => {
	// console.log(config)
	loadingInstance =  Loading.service({
		lock: true,
		text: '加载中...',
		spinner: 'el-icon-loading',
	});
    if (localStorage.getItem('token')) {
        config.headers.Authorization = localStorage.getItem('token');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */

instance.interceptors.response.use((response) => {
	loadingInstance.close();
	if(response.data.status === 204) {
		setTimeout(() =>{
			localStorage.clear()
			router.push({
				path: '/',
			})
		}, 2000)
	}
	if (response.data.code === 4000) {
		MessageBox('比赛结束啦', '提示消息', {
			confirmButtonText: '确定',
		}).then(() => {
			localStorage.clear()
			router.push({
				path: '/',
			})
		});
	}
    return response.data;
}, (error) => {
	loadingInstance.close();
    return Promise.reject(error);
});


const Http = {};

/* get方法参数以param对象传参 */
Http.axiosGetBy = (url, params, callback, reject) => {
    instance.get(url, {params: params}).then(callback).catch(reject);
}

/* get方法参数跟在url中 */
Http.axiosGet = (url, callback, reject) => {
    instance.get(url).then(callback).catch(reject);
}

Http.asyncGet = (url, params) => {
	return instance.get(url, {params: params});
}

Http.asyncPost = (url, params) => {
	return instance.post(url, params)
}

/* post方法 */
Http.axiosPost = (url, params, callback, reject) => {
    instance.post(url, params).then(callback).catch(reject);
}

/* 上传文件 */
Http.axiosFile = (url, formData, callback, reject) => {
	let config = {
	        headers: {
	            'Content-Type': 'multipart/form-data'
	        }
	    }
	instance.post(url, formData, config).then(callback).catch(reject);
}
/*-无加载状态的请求-*/
const requestInstance = axios.create({});

requestInstance.interceptors.request.use((config) => {
	if (localStorage.getItem('token')) {
		config.headers.Authorization = localStorage.getItem('token');
	}
	return config;
}, (error) => {
	return Promise.reject(error);
});

requestInstance.interceptors.response.use((response) => {
	return response.data;
}, (error) => {
	return Promise.reject(error);
});

Http.onlyPost = (url, params, callback, reject) => {
	requestInstance.post(url, params).then(callback).catch(reject);
}
Http.onlyGet = (url, callback, reject) => {
	requestInstance.get(url).then(callback).catch(reject);
}

Http.onlyGetBy = (url, params, callback, reject) => {
	requestInstance.get(url, params).then(callback).catch(reject);
}

/**无状态请求 */
const withoutInstance = axios.create({});
/* 请求拦截器 */
withoutInstance.interceptors.request.use((config) => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = localStorage.getItem('token');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */
withoutInstance.interceptors.response.use((response) => {
	if(response.data.status === 204) {
		setTimeout(() =>{
			localStorage.clear()
			router.push({
				path: '/',
			})
		}, 2000)
	}
	if (response.data.code === 4000) {
		MessageBox('比赛结束啦', '提示消息', {
			confirmButtonText: '确定',
		}).then(() => {
			localStorage.clear()
			router.push({
				path: '/',
			})
		});
	}
    return response.data;
}, (error) => {
    return Promise.reject(error);
});

/* get方法参数以param对象传参 */
Http.withoutGetBy = (url, params, callback, reject) => {
    withoutInstance.get(url, {params: params}).then(callback).catch(reject);
}

/* get方法参数跟在url中 */
Http.withoutGet = (url, callback, reject) => {
    withoutInstance.get(url).then(callback).catch(reject);
}

Http.withoutPost = (url, params, callback, reject) => {
    withoutInstance.post(url, params).then(callback).catch(reject);
}

export default Http;

