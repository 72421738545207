<template>
  <div class="student-container">
    <div class="left-wrapper">
      <div class="left-header">
        <div class="logo">
          <i class="iconfont">&#xe636;</i>
        </div>
        <p class="theme">直播运营理实一体化软件</p>
      </div>
      <el-scrollbar class="left-menu-wrapper" :native="false">
        <el-menu :default-active="menuActive" mode="vertical" class="left-menu">
          <el-menu-item index="liveGoodsManagement">
            <router-link class="nav-item" to="/student/liveStreamingSales/goodsManagement"> <i class="iconfont">&#xe619;</i>直播商品管理</router-link>
          </el-menu-item>
          <el-menu-item index="liveMarketing">
            <router-link class="nav-item" to="/student/liveStreamingSales/marketing"> <i class="iconfont">&#xe610;</i>直播营销</router-link>
          </el-menu-item>
          <el-menu-item index="liveRoomsManagement" v-show="!showAddRoom">
            <router-link class="nav-item" to="/student/liveStreamingSales/roomsManagement"> <i class="iconfont">&#xe63f;</i>直播间管理</router-link>
          </el-menu-item>
          <el-menu-item index="liveRoomManageCreateLive" v-show="showAddRoom">
            <router-link class="nav-item" to="/liveRoomManage/createLive"> <i class="iconfont">&#xe63f;</i>直播间管理</router-link>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
      <div class="left-download">
        <div class="download-item">
          <span class="left">商品介绍文档</span>
          <span class="right" @click="openDownFile(1, moduleId)" style="cursor: pointer">下载素材</span>
        </div>
        <div class="download-item">
          <span class="left">人物设定文档</span>
          <span class="right" @click="openDownFile(2, moduleId)" style="cursor: pointer">下载素材</span>
        </div>
      </div>
    </div>
    <div class="right-wrapper">
      <div class="right-header">
        <div class="countdown">
          <!--倒计时-->
          <CountDown></CountDown>
        </div>
        <div class="user-wrapper">
          <div class="user-info">
            <div class="user-avatar">
              <img :src="userIcon ? userIcon : require('../../../assets/images/user_img.png')" alt="" />
            </div>
            <div class="user-name">{{ username }}</div>
          </div>
          <div class="exit-btn" @click="backTo">
            <i class="iconfont">&#xe642;</i>
            <span>返回主界面</span>
          </div>
        </div>
      </div>
      <el-scrollbar class="right-content" :native="false">
        <div class="right-wrapper-content">
          <router-view />
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import CountDown from "../../../components/student/CountDown.vue";
import { submitZhibo } from "@/utils/apis";

export default {
  data() {
    return {
      menuActive: "",
      username: "",
      userIcon: "",
      downFile: {
        good_path: "",
        person_path: "",
      },
      moduleId: void 0,
      showAddRoom: false,
    };
  },
  components: {
    CountDown,
  },
  watch: {
    $route(route) {
      this.menuActive = route.matched[1].name;
      this.showAddRoom = !!route.meta.parentName ? true : false;
    },
  },
  mounted() {
    this.username = localStorage.getItem("username");
    let userIcon = localStorage.getItem("headerImg");
    if (userIcon !== "null") {
      this.userIcon = userIcon;
    }
    this.moduleId = Number(localStorage.getItem("liveMarketModuleId"));
  },
  created() {
    this.menuActive = this.$route.matched[1].name;
    this.showAddRoom = !!this.$route.meta.parentName ? true : false;
  },
  methods: {
    openDownFile(type, id) {
      let url = `${window.location.origin}/live_sale/download?type=${type}&id=${id}`;
      window.open(url, "_blank");
    },
    // 返回主界面
    async backTo() {
      const res = await submitZhibo();
      if (res.code === 200) {
        this.$router.push({ path: "/student/competition/index", query: { modules: localStorage.getItem("liveModules") } });
      } else {
        this.$message.warning("提交答案失败");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.student-container {
  background: #f7f9fb;
  width: 100vw;
  height: 100vh;
  display: flex;
  .left-wrapper {
    width: 280px;
    background: #2c405f;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    .left-header {
      text-align: center;
      .logo {
        margin-top: 36px;
        display: inline-block;
        width: 60px;
        height: 60px;
        background: #fd4446;
        border-radius: 10px;
        text-align: center;
        line-height: 60px;
        .iconfont {
          display: block;
          font-size: 33px;
          color: #fff;
        }
      }
      .theme {
        margin-top: 22px;
        font-size: 23px;
        color: #fff;
        line-height: 1;
      }
    }
    .left-menu-wrapper {
      margin-top: 50px;
      height: 1%;
      flex: 1;
      /deep/ .el-scrollbar__wrap {
        overflow-x: hidden;
      }
      .left-menu {
        height: 100%;
        background: transparent;
        border-right: none;
      }
      .el-menu-item {
        height: 60px;
        line-height: 60px;
        padding: 0 !important;
        .nav-item {
          height: 100%;
          color: #f7f9fb;
          font-size: 18px;
          display: flex;
          align-items: center;
          padding: 0 20px 0 40px;
          position: relative;
          transition: all 0.3s;
          .iconfont {
            color: #f7f9fb;
            font-size: 24px;
            margin-right: 20px;
            transition: all 0.3s;
          }
        }
        &:focus,
        &:hover,
        &.is-active {
          background-color: #25354f;
        }
        &.is-active {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            width: 2px;
            height: 100%;
            background: #ff4400;
          }
        }
      }
    }
    .left-download {
      margin-bottom: 100px;
      color: #fff;
      .download-item {
        padding: 0 25px 0 40px;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        .right {
          color: #fd4446;
        }
      }
    }
  }
  .right-wrapper {
    width: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .right-header {
      height: 80px;
      background: #fff;
      border-bottom: 1px solid #d1d2db;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .countdown {
        font-size: 18px;
        color: #fd4446;
        font-weight: 500;
        .time {
          margin-left: 10px;
        }
      }
      .user-wrapper {
        position: absolute;
        right: 30px;
        display: flex;
        .user-info {
          display: flex;
          align-items: center;
          .user-avatar {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid #eee;
            box-sizing: border-box;
            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .user-name {
            margin-left: 6px;
          }
        }
        .exit-btn {
          margin-left: 24px;
          display: flex;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s;
          .iconfont {
            font-size: 20px;
          }
          span {
            margin-left: 6px;
          }
          &:hover {
            color: #1122d8;
          }
        }
      }
    }
    /deep/ .right-content {
      height: 1%;
      flex: 1;
      .el-scrollbar__wrap {
        overflow-x: hidden;
        .el-scrollbar__view {
          min-width: 900px;
          /*height: 100%;*/
        }
        .right-wrapper-content {
          padding: 30px;
          box-sizing: border-box;
        }
      }
    }
  }
}
/deep/ p {
  margin: 0;
}
</style>
