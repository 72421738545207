<template>
    <div class="education-content tabs-full">
        <el-tabs v-model="decorationTab" type="card" @tab-click="toggleOperationTab">
            <el-tab-pane label="新客服管理" name="newstoreCustomerData"></el-tab-pane>
            <el-tab-pane label="直播销售" name="AdminLiveMarket"></el-tab-pane>
        </el-tabs>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                decorationTab: 'newstoreCustomerData',
            }
        },
        created() {
            this.decorationTab = this.$route.meta.tabName;
        },
        watch: {
            $route(route) {
                if (route.name === 'newstoreCustomerData') {
                    this.decorationTab = 'newstoreCustomerData'
                }
                if (route.name === 'AdminLiveMarket') {
                    this.decorationTab = 'AdminLiveMarket'
                }
            }
        },
        methods: {
            toggleOperationTab(tab) {
                this.$router.push({
                    name: tab.name
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        .container {
            flex: 1;
            background: #fff;
            overflow: hidden;
        }
    }
</style>