import { get, post } from "@/utils/request";
import Http from "@/utils/http";

export const topicCateAdd = (formdata) => {
    return post("theoryTopicCategory/edit", formdata);
};

export const topicCateList = (param) => {
    return get("theoryTopicCategory/getList", param);
};

export const topicLibarayDel = (formdata) => {
    return post("theoryTopicCategory/del", formdata);
};

export const theoryTopicList = (param) => {
    return get("theoryTopic/getList", param);
};

export const theoryLibSelect = () => {
    return get("theoryTopicCategory/getBox");
};
export const theoryTopicDel = (formdata) => {
    return post("theoryTopic/del", formdata);
};
export const downloadTopicTmpl = () => {
    return get("theoryTopic/downloadTopicTem");
};
export const theoryTopicExcel = (formdata) => {
    return post("theoryTopic/excel", formdata);
};

//数据中心-理论榜单
export const showTheoryListData = (params) => {
    return get("theorytrain/index", params);
};
// 数据中心-理论榜单-删除
export const delTheoryListData = (params) => {
    return post("theorytrain/del", params);
};
// 训练管理员-理论榜单-学生列表
export const showStudentTheoryList = (params) => {
    return get("theorytrain/student", params);
};
// 训练管理员-问答评分 列表
export const showProblemListData = (params) => {
    return get("theorytrain/scores_index", params);
};

// 训练管理员-问答评分 答案列表
export const showAnswer = (params) => {
    return get("theorytrain/answer", params);
};

// 训练管理员-问答评分 评分
export const updateAnswerListData = (params) => {
    return post("theorytrain/scores", params);
};

// 学生答题次数列表
export const showAnswerStudentList = (params) => {
    return get("studentTrain/studentAnswerList", params);
};

// 训练管理员学生答题次数列表
export const showStudentAnswerListData = (params) => {
    return get("studentTrain/studentAnswerListTeacher", params);
};

// 试卷列表
export const showPaperList = (params) => {
    return get("theoryPaper/randList", params);
};

// 评委评分列表
export const showScoreList = (params) => {
    return get("contest/score_list", params);
};

// 评委修改评分
export const editScore = (params) => {
    return post("contest/score", params);
};

export const theoryTopicAdd = (formdata) => {
    return post("theoryTopic/edit", formdata);
};

export const theoryTopicDetail = (param) => {
    return get("theoryTopic/getDetail", param);
};

export const assignTopicCategory = (param) => {
    return get("theoryTopic/assignTopicCategory", param);
};

export const theoryTopicGetSchoolBox = (param) => {
    return get("theoryTopicCategory/getSchoolBox", param);
};

export const theorytrainStart = (param) => {
    return post("theorytrain/start", param);
};

export const theorytrainEnd = (param) => {
    return post("theorytrain/end", param);
};

export const theorytrainDel = (param) => {
    return post("theorytrain/del", param);
};

export const theorytrainTopic = (param) => {
    return get("theorytrain/topic", param);
};

export const collegeClass = (param) => {
    return get("collegeClass", param);
};

export const theorytrainSave = (formdata) => {
    return post("theorytrain/save", formdata);
};

export const studentTrainLoading = (param) => {
    return get("studentTrain/loading", param);
};

export const studentTrainTrainReset = (param) => {
    return get("studentTrain/trainReset", param);
};

export const theoryStuDetail = (param) => {
    return get("studentTrain/startTrain", param);
};

export const theorySave = (formdata) => {
    return post("studentTrain/saveAnswerLog", formdata);
};

export const studentAnswerDetail = (param) => {
    return get("studentTrain/studentAnswerDetail", param);
};

export const studentAnswerDetailTeacher = (param) => {
    return get("studentTrain/studentAnswerDetailTeacher", param);
};
export const stuLogout = () => {
    return get("login/out");
};

export const theorytrainInfo = (param) => {
    return get("theorytrain/info", param);
};

export const getDataCenter = (param) => {
    return get("data", param);
};

export const getComList = (param) => {
    return get("com/comList", param);
};

export const getStudentExamDetail = (param) => {
    return get("studentAnswer/studentExamDetail", param);
};
export const theoryGetPaperList = (param) => {
    return get("theoryPaper/randList", param);
};

export const theoryPaperQuestionList = (param) => {
    return get("theoryPaper/getTopicList", param);
};

export const theoryPaperCreate = (param) => {
    return post("theoryPaper/editChoose", param);
};

export const theoryRandomPaperCreate = (param) => {
    return post("theoryPaper/editRand", param);
};
export const theoryCompetitionInfo = (param) => {
    return get("studentAnswer/startExam", param);
};
export const stuTheoryCompetitionInfo = (param) => {
    return get("studentAnswer/studentExamDetail", param);
};
export const stuSaveTheoryCompetition = (formdata) => {
    return post("studentAnswer/saveLog", formdata);
};

export const theoryGetArtificialPaperDetail = (formdata) => {
    return get("theoryPaper/getChooseDetail", formdata);
};

export const theoryGetRandomPaperDetail = (formdata) => {
    return get("theoryPaper/getRandDetail", formdata);
};

export const theoryDeletePaper = (formdata) => {
    return post("theoryPaper/delPaper", formdata);
};

export const competitionInfo = () => {
    return get('com/info')
}


/* 数据管理 */
export const getAnalysisListData=(param)=>{
    return get('data_manage/list',param)
}
export const getAnalysisGoodsData=(params)=>{
    return get('data_manage/goods',params)
}
export const createAnalysisData=(params)=>{
    return post('data_manage/operate',params)
}
export const delAnalysisData=(params)=>{
    return post('data_manage/del',params)
}
export const createService=(params)=>{
    return post('data_manage/service',params)
}
export const getEditAnalysisData=(params)=>{
    return get('data_manage/info',params)
}
export const getPrice=(params)=>{
    return post('data_manage/price',params)
}
// 竞赛模板
export const getCustomServiceList=(params)=>{
    return get ('/dataTemplate/list',params)
}

// 新客服管理
export const getNewKeFuListData=(param)=>{
    return get('new_kefu/listKefuData',param)
}
export const getKefuData=(param)=>{
    return get('new_kefu/getKefuData',param)
}
export const getStuKefuExamDetail=(param)=>{
    return get('/new_kefu/stuKefuExamDetail',param)
}

export const getStuKefuTrainDetail=(param)=>{
    return get('new_kefu/stuKefuTrainDetail',param)
}
export const getKeFuCreateSN=(param)=>{
    return get('new_kefu/createSN',param)
}
export const postDelKefuData=(param)=>{
    return post('new_kefu/delKefuData',param)
}
//学生端客服考试/训练
export const getStudentGoods = params => {
    return get(localStorage.getItem("type") == 1 ? "kefu_stu/good" : "kefu_drill/good", params)
}
export const getStudentServiceInfoAPI = params => {
    return get(localStorage.getItem("type") == 1 ? "kefu_stu" : "kefu_drill", params)
}
export const getStudentHistoryInfoAPI = params => {
    return get(localStorage.getItem("type") == 1 ? "kefu_stu/historical" : "kefu_drill/historical", params)
}
export const studentUsePaperStatus = params => {
    return get(localStorage.getItem("type") == 1 ? "kefu_stu/status" : "kefu_drill/status", params)
}
export const studentServiceSubmitRobotAPI = params => {
    return post(localStorage.getItem("type") == 1 ? "kefu_stu/submit" : "kefu_drill/submit", params)
}
export const studentServiceSubmitUnfinishedRobot = params => {
    return post(localStorage.getItem("type") == 1 ? "kefu_stu/submitEven" : "kefu_drill/submitEven", params)
}
//快捷回复
export const getGroupListAPI = params => {
    return get("group/list", params)
}
export const getReplyDataAPI = params => {
    return get("terms/list", params)
}
export const delGroupAPI = params => {
    return get("group/del", params)
}
export const delReplyAPI = params => {
    return get("terms/del", params)
}
export const saveGroupAPI = params => {
    return post("group/save", params)
}
export const saveReplyAPI = params => {
    return post("terms/save", params)
}
export const exportReplyAPI = params => {
    return get("terms/export", params)
}
export const importReplyAPI = params => {
    return post("terms/import", params)
}
export const uploadReplyImg = params => {
    return post("terms/upload", params)
}
export const replyOffsetAPI = params => {
    return get("terms/operate", params)
}
export const groupOffsetAPI = params => {
    return get("group/operate", params)
}
//--
export const SaveKefuData = params => {
    return post("new_kefu/saveKefuData", params)
}

export const postCustomerData=(param)=>{
    return post('customer_data_analysis/save_data_analysis',param)
}
//数据模块管理
export const getDataTemplateListAPI = params => {
    return get("/dataTemplate/list", params)
}
export const getDataTemplateDetailAPI = params => {
    return get("/dataTemplate/detail", params)
}
export const delDataTemplateAPI = params => {
    return post("/dataTemplate/del", params)
}
export const copyDataTemplateAPI = params => {
    return get("/dataTemplate/copy", params)
}
export const editDataTemplateAPI = params => { // 修改/添加
    return post("/dataTemplate/edit", params)

}

export const getCustomerMenu = params => {
    return get('customer_data_analysis/exam_module', params)
}
export const getDataAnalysisInfo = params => {
    return get('customer_data_analysis/data_analysis_info', params)
}

export const getDataAnalysisExamDet = params => {
    return get('customer_data_analysis/data_analysis_exam_det',params)
}
export const getDataAnalysisTrainDet = params => {
    return get('customer_data_analysis/data_analysis_train_det',params)
}
export const getShopSituation = params => {   // 数据汇总 店铺概况
    return get('student_data_manage/shop_situation',params)
}
export const getPeopleFace = params => {   // 数据汇总 人群画像
    return get('student_data_manage/people_face',params)
}
export const getShopFlow = params => {   // 数据汇总 店铺流量
    return get('student_data_manage/shop_throughput',params)
}
export const getProductData = params => {   // 数据汇总 商品数据
    return get('student_data_manage/goods_data',params)
}
export const getServiceData = params => {   // 数据汇总 客服数据
    return get('student_data_manage/service_data',params)
}
export const getdataAnalysis = params => {   // 评委端 数据分析模块
    return get('customer_data_analysis/judgeList',params)
}
export const getScoreList = params => {   // 评委端 评委评分详细页列表
    return get('customer_data_analysis/judgeScoreList',params)
}
export const postJudgeScore = params => {   // 评委端 评委评分详细页列表
    return post('customer_data_analysis/setJudgeScore',params)
}
export const getNewkefuCopy = params => {
    return get('new_kefu/copy',params)
}
export const copyDataManager=params=>{
    return get('data_manage/copy',params)
}

export const copySearchADTemplateAPI = params => {
    return get("sd/copy", params);
}
export const copyGoodsInfoTemplateAPI = params => {
    return get("itemInfo/copy", params);
}
export const copyShopDecorateTemplateAPI = params => {
    return post("decorate/copy_tem", params);
}
export const storeSettingsCopyTemAPI = params => {
    return post("store_settings/copy_tem", params);
}
export const storeCopy=params=>{
    return post('market/copy_tem',params)
}
export const copyHotKeywordsAPI = params => {
    return get("hk/copy", params);
}
export const copyBooth=params=>{
    return get('booth/copy',params)
}
export const copyKeFuAPI = params => {
    return get("kefu/copyKefu", params);
}
export const copyStoreSelection = params => {
    return get("/storeSelection/copy", params);
}
export const  copyHkg = params => {
    return get(" /hkg/copy", params);
}
export const  dataExamModule = params => {
    return get("/data/exam_module", params);
}
export const dataAnalysisSend=params=>{
    return post('/data_manage/send',params)
}
export const sendHotKeywordsAPI = params => {
    return post("hk/send", params);
}
export const sendSearchADTemplateAPI = params => {
    return post("sd/send", params);
}

export const sendDecorateAPI = params => {
    return post("decorate/send_tem", params)
}

export const sendBooth=params=>{
    return get('booth/send',params)
}

export const sendGoodsInfoAPI = params => {
    return post("itemInfo/send", params)
}
export const  getNewkefuSend = params => {
    return get("new_kefu/send", params)
}

export const sendMarket=params=>{
    return post('/market/send_tem',params)
}
export const postHkgSend=params=>{
    return post('/hkg/send',params)
}
export const postStoreSelectionSend=params=>{
    return post('/storeSelection/send',params)
}

export const sendKeFuGoodsAPI = params => {
    return post("kefu/goods/send", params);
}

export const sendKeFuAPI = params => {
    return post("kefu/send", params);
}

export const sendDataTemplate = params => {
    return post("dataTemplate/send", params);
}
export const competitionModules = (id) => {
    return get("/data/exam_module?id=" + id)
}
export const calculateDecorate = params => {
    return post("decorate/calculate_score", params)
}

export const stuEndingPromotion = params => {
    return new Promise((resolve, reject) => {
        Http.asyncPost('stuep/ending', params).then(res => resolve(res)).catch(err => reject(err))
    })
}
export const stuPlanKeywords = param => {
    return Http.asyncGet("stuep/keywordids", param)
}
export const keywordOfferRank = () => {
    return Http.asyncGet('stuep/kwstoreoffer')
}

export const uploadVideo = (form) => {
    return post("decorate/upload_video", form, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const getShopRegister=()=>{
    return get('/store_settings/list')
}

// 直播商品列表
export const getLiveGoodsList=(params)=>{
    return get('/live_sale_student/goods',params)
}
// 直播商品列表编辑
export const saveLiveGoodsList=(params)=>{
    return post('/live_sale_student/operate_goods',params)
}
// 直播销售列表
export const getLiveSales=(params)=>{
    return get("/live_sale/list",params)
}
// 删除优惠券
export const delCouponItem=(params)=>{
    return post('/live_sale_student/delete_coupon',params)
}
// 优惠券列表
export const getCouponList=(params)=>{
    return get('/live_sale_student/coupon',params)
}
// 添加优惠券
export const saveCouponData=(params)=>{
    return post('/live_sale_student/operate_coupon',params)
}
// 添加优惠券
export const getCouponDetail=(params)=>{
    return get('/live_sale_student/coupon_info',params)
}
// 任务详情
export const getTaskTotalDetail=()=>{
    return get('/guo/introduction/taskDetail')
}
export const postOperateRoom=(params)=>{
    return post('/live_sale_student/operate_room', params)
}
export const postRoomList=()=>{
    return get('/live_sale_student/room')
}
export const getRoomInfo=(params)=>{
    return get('/live_sale_student/room_info', params)
}
export const postDeleteRoom=(params)=>{
    return post('/live_sale_student/delete_room', params)
}
// 获取学生训练详情
export const liveSaleTeacherTrainDetail=(params)=>{
    return get('/live_sale_teacher/train_detail', params)
}
// 获取评委评分
export const getLive_sale_teacherJudge_score=(params)=>{
    return get('/live_sale_teacher/judge_score', params)
}
export const liveSaleTeacherCompetitionDetail=(params)=>{
    return get('/live_sale_teacher/competition_detail', params)
}
export const live_sale_teacherSubmit=(params)=>{
    return post('/live_sale_teacher/submit', params)
}

export const uploadWebm = (form_data) => {
    return post("guo/webm/upload", form_data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
}

export const nextCycle = () => {
    return post("ep/next_cycle")
}

export const saveSearchAdsData = (form_data) => {
    return post("sd/saveSD", form_data)
}

export const batchSaveAdsData = (form_data) => {
    return post("ads/kys", form_data)
}
export const submitZhibo = () => {
    return post("live_sale_student/submit")
}

export const generateSearchAdsData = (form_data) => {
    return post("sd/gData", form_data)
}
export const getGoodList=(params)=>{
    return get('kefu/showGoods', params)
}

// 检查学生是否重复直播
export const getLiveSaleStudentCheck=(params)=>{
    return get('live_sale_student/check', params)
}

export const getIp = () => {
    return get("ip")
}

export const getGoodInfo=(params)=>{
    return get('live_sale_student/goods_data',params)
}