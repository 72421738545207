import Vue from 'vue'
import Vuex from 'vuex'
import Http from '../utils/http';
import Api from "@/utils/api";
import _ from "underscore";
import Common from "../utils/common";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        streamIp: "",
        operationModules: [],
        keywords: [],
        keywordsData: [],
        peopleOrients: [],
        decorationMenus: [
            {
                icon: '',
                name: '',
                route: '',
                state: 1
            },
            {
                icon: '',
                name: '',
                route: '',
                state: 1
            },
            {
                icon: '',
                name: '',
                route: '',
                state: 1
            },
            {
                icon: '',
                name: '',
                route: '',
                state: 1
            },
            {
                icon: '',
                name: '',
                route: '',
                state: 1
            },
            {
                icon: '',
                name: '',
                route: '',
                state: 1
            }
        ],
        dataMenus: [
            {
                icon: '&#xe64a;',
                // name: '店铺选品',
                name: '搜索引擎优化',
                children: [
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                ]
            },
            // {
            //     icon: '&#xe63c;',
            //     name: '店铺营销',
            //     children: [
            //         {
            //             name: '',
            //             route: '',
            //             status: 0
            //         },
            //         {
            //             name: '',
            //             route: '',
            //             status: 0
            //         },
            //         {
            //             name: '',
            //             route: '',
            //             status: 0
            //         },
            //         {
            //             name: '',
            //             route: '',
            //             status: 0
            //         },
            //     ]
            // },
            {
                icon: '&#xe639;',
                name: '店铺引流',
                children: [
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                    {
                        name: '',
                        route: '',
                        status: 0
                    },
                ]
            }
        ],
        goodsList: [],
        nowTime: 0,
        options: [],
        answer: null,
        live_good_id:void 0,
        live_goodsList:[],
        cycle_value: "",
    },
    getters: {
        streamIpValue: state => {
            return state.streamIp
        },
        cycleValue: state => {
            return state.cycle_value
        },
        operationM: state => {
            return state.operationModules
        },
        keywordLists: state => {
            return state.keywords;
        },
        peopleOrientList: state => {
            return state.peopleOrients;
        },
        keywordData: state => {
            return state.keywordsData;
        },
        decorationMenuList: state => {
            return state.decorationMenus;
        },
        dataMenuList: state => {
            return state.dataMenus;
        },
        goodsList: state => {
            return state.goodsList;
        },
        nowTimeStamp: state => {
            return state.nowTime;
        },
        getOptions: state => {
            return state.options
        },
        answerInfo: state => {
            return state.answer
        },
    },
    mutations: {
        setStreamValue: (state, payload) => {
            state.streamIp = payload
        },
        setCycleValue: (state, payload) => {
            state.cycle_value = payload
        },
        setOperationModules: (state, payload) => {
            state.operationModules = payload
        },
        setTimeStamp: (state, playload) => {
            state.nowTime = playload;
        },
        setKeyWordList: (state, playload) => {
            state.keywords = playload;
        },
        setPeopleOrientList: (state, playload) => {
            state.peopleOrients = playload;
        },
        setKeyWordData: (state, playload) => {
            state.keywordsData = playload;
        },
        setDecorationMenus: (state, playload) => {
            state.decorationMenus = playload;
        },
        setDataMenus: (state, playload) => {
            state.dataMenus = playload;
        },
        setGoodsList: (state, playload) => {
            state.goodsList = playload;
        },
        setOptions: (state, playload) => {
            // console.log('option', playload);
            state.options = playload;
        },
        setAnswer: (state, payload) => {
            state.answer = payload;
        },
        setLiveGoodsID:(state,playload)=>{
            state.live_good_id=playload
        },
        setLiveGoods:(state,playload)=>{
            state.live_goodsList=playload
        }
    },
    actions: {
        SET_STREAM_IP: (context, args) => {
            context.commit("setStreamValue", args)
        },
        SET_CYCLE_VALUE: (context, args) => {
            context.commit("setCycleValue", args)
        },
        COMMITOPERATIONMODULES: (context, args) => {
            context.commit("setOperationModules", args)
        },
        setAnswerValue: (context, args) => {
            context.commit('setAnswer', args)
        },
        setNowTimeStamp: (context, args) => {
            context.commit('setTimeStamp', args);
        },
        setKeyWordList: ({ commit }) => {
            new Promise((resolve, reject) => {
                Http.axiosGet(Api.SDepkList, (res) => {
                    if (res.code === 200) {
                        commit('setKeyWordList', res.data.data);
                        resolve(res.data.data);
                    } else {
                        commit('setKeyWordList', []);
                    }
                }, (err) => {
                    reject(err);
                })
            }).then(res => {
                let ids = [];
                _.each(res, (item) => {
                    ids.push(item.keyword_id);
                });
                if (ids.length === 0) {
                    return;
                }
                let param = {
                    nopage: 1,
                    id: ids.join(',')
                }
                Http.axiosGetBy(Api.ep_kwsdata, param, (resp) => {
                    if (resp.code === 200) {
                        commit('setKeyWordData', resp.data.list);
                    }
                }, (err) => {
                    reject(err);
                })

            }).catch(err => {
                console.log(err);
            })

        },
        setPeopleOrientList: ({ commit }) => {
            Http.axiosGet(Api.SDeppList, (res) => {
                if (res.code === 200) {
                    commit('setPeopleOrientList', res.data);
                } else {
                    commit('setPeopleOrientList', []);
                }
            }, (err) => {
                console.log(err);
            })
        },
        setDecorationMenusList: ({ commit}) => {
            Http.axiosGet(Api.decorate_zhuangxiuStateList, (res) => {
                if (res.code === 200) {
                    commit('setDecorationMenus', res.data);
                }
            }, (err) => {
                Common.axiosErrorMsg(err);
            })
        },
        setDataMenusList: ({commit}) => {
            Http.axiosGet(Api.data_userOpList, (res) => {
                if (res.code === 200) {
                    commit('setDataMenus', res.data);
                    
                }
            }, (err) => {
                Common.axiosErrorMsg(err);
            })
        },
        setGoodsLists: (context, args) => {
            context.commit('setGoodsList', args);
        },
        setOptionsValue: (context, args) => {
            context.commit('setOptions', args)
        },
        setLiveGoodID:(context,args)=>{
            context.commit('setLiveGoodsID',args)
        },
        setLiveGoods:(context,args)=>{
            context.commit('setLiveGoods',args)
        }
    },
    modules: {},
    plugins: [ createPersistedState() ],
})