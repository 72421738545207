<template>
    <div class="operation-wrapper">
        <el-tabs
            v-model="operationTab"
            type="card"
            class="tabs-full"
            @tab-click="toggleOperationTab"
        >
            <el-tab-pane label="试题管理" name="topicManage"></el-tab-pane>
            <el-tab-pane label="试卷管理" name="PaperManage"></el-tab-pane>
            <el-tab-pane label="题库管理" name="topicLibrary"></el-tab-pane>
        </el-tabs>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            operationTab: "topicManage",
        };
    },
    created() {
        this.operationTab = this.$route.name;
    },
    watch: {
        $route(route) {
            if (route.name === "topicManage") {
                // this.$router.push('/admin/operatedata/index')
                this.operationTab = "topicManage";
            }
        },
    },
    methods: {
        toggleOperationTab(tab) {
            this.$router.push({
                name: tab.name,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.operation-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .container {
        // height: 100%;
        flex: 1;
        background: #fff;
        overflow: hidden;
    }
}
</style>
